const StandardizedFinancialStatement = require('./standardized_financial_statement')

class BalanceSheetStatement extends StandardizedFinancialStatement {
  constructor(financials) {
    super(financials)

    this.defineProportionFamilyBoundariesByTagName(
      'totalassets',
      'totalassets'
    )

    this.defineProportionFamilyBoundariesByTagName(
      'totalliabilitiesandequity',
      'totalliabilitiesandequity'
    )
  }
}

module.exports = BalanceSheetStatement
