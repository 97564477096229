const apiService = require('services/apiService')

function slugify(string) {
  const a = 'àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;'
  const b = 'aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------'
  const p = new RegExp(a.split('').join('|'), 'g')

  return string.toString().toLowerCase()
    .replace(/\s+/g, '-') // Replace spaces with -
    .replace(p, c => b.charAt(a.indexOf(c))) // Replace special characters
    .replace(/&/g, '-and-') // Replace & with 'and'
    .replace(/[^\w\-]+/g, '') // Remove all non-word characters
    .replace(/\-\-+/g, '-') // Replace multiple - with single -
    .replace(/^-+/, '') // Trim - from start of text
    .replace(/-+$/, '') // Trim - from end of text
}

class User {
  constructor(fields) {
    Object.assign(this, fields)
  }

  getList(listName) {
    let lists = this.lists

    for (let i = 0; lists.length > i; i++) {
      let list = lists[i]
      if (slugify(list.name) == slugify(listName)) return list
    }
  }

  listHasTicker(listName, ticker) {
    let list = this.getList(listName)

    for (let i = 0; list.items.length > i; i++) {
      let item = list.items[i]
      if (item.ticker.toLowerCase() == ticker.toLowerCase()) return true
    }

    return false
  }

  addTickerToList(ticker, listName) {
    let newItem = {
      ticker,
      additionDate: new Date()
    }

    let list = this.getList(listName)

    if (list && !this.listHasTicker(listName, ticker)) {
      return list.items.push(newItem)
    }

    this.lists.push({
      name: listName,
      items: [
        newItem
      ]
    })
  }

  removeTickerFromList(ticker, listName) {
    this.lists = this.lists.map(list => {
      if (slugify(list.name) != slugify(listName)) return list

      list.items = list.items.filter(item => {
        return item.ticker.toLowerCase() != item.ticker.toLowerCase()
      })

      return list
    })
  }

  addTickerToBookmarks(ticker) {
    this.addTickerToList(ticker, 'Bookmarks')
  }

  isBookmarked(ticker) {
    return this.listHasTicker('Bookmarks', ticker)
  }

  removeTickerFromBookmarks(ticker) {
    this.removeTickerFromList(ticker, 'Bookmarks')
  }

  save(fieldsParam) {
    let fields = fieldsParam || {
      givenName: this.givenName,
      familyName: this.familyName,
      passphrase: this.passphrase,
      lists: this.lists
    }

    return apiService.put('/users/me', fields).then(res => {
      return res.data
    })
  }
}

module.exports = User
