import PrimaryNav from 'components/PrimaryNav'
import Footer from 'components/Footer'

export default class Layout extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div className={`layout${this.props.className ? ' '+ this.props.className : ''}`}>
        { this.props.showNav != false ? <PrimaryNav hideSearch={this.props.hideSearch}/> : undefined }
        <main>
          {this.props.children}
        </main>
        { this.props.hideFooter != true ? <Footer/> : undefined }
      </div>
    )
  }
}
