const apiService = require('./apiService')



function screenCompanies(opts) {
  let options = opts.compact ? { params: { compact: opts.compact } } : null
  
  return apiService.post('/screens', opts, options).then((res) => {
    if (!opts.compact) return res.data.data

    return res.data.data.split('\n').map(line => {
      return line.split('|').reduce((acc, cur, ix) => {
        let fields = opts.compact.split(',')
        acc[fields[ix]] = cur
        return acc
      }, {})
    })
  })
}



module.exports = {
  screenCompanies,
}
