import Link from 'next/link'
import { withRouter } from 'next/router'
import Mousetrap from 'mousetrap'
import Dropdown from 'react-bootstrap/Dropdown'
import Nav from 'react-bootstrap/Nav'
import Container from 'react-bootstrap/Container'
import Button from 'react-bootstrap/Button'
import Badge from 'react-bootstrap/Badge'
import Modal from 'react-bootstrap/Modal'

import AppContext from 'components/AppContext'
import CompanyFinder from 'components/company/CompanyFinder'

import HomeSvg from 'open-iconic/svg/home.svg'
import MagnifyingGlassSvg from 'open-iconic/svg/magnifying-glass.svg'
import MenuSvg from 'open-iconic/svg/menu.svg'

class PrimaryNavMenu extends React.Component {
  static contextType = AppContext

  render() {
    let {user, listItems, lists} = this.context

    let listList = lists ? (
      <>
        { lists.slice(0,5).map(list => (
          <Link href="/lists/[id]" as={`/lists/${list.id}`} passHref key={list.id}>
            <Dropdown.Item>{list.name}</Dropdown.Item>
          </Link>
        )) }
        <Dropdown.Divider/>
        { lists.length > 5 ? (
          <Link href="/lists" passHref>
            <Dropdown.Item>More Lists</Dropdown.Item>
          </Link>
        ) : null}
      </>
    ) : null

    let userLinks = user ? (
      <>
        <Link href="/" passHref>
          <Dropdown.Item>Home</Dropdown.Item>
        </Link>
        <Link href="/browse" as="/browse" passHref>
          <Dropdown.Item>Browse</Dropdown.Item>
        </Link>
        <Link href="/indices/[id]" as="/indices/sp-500" passHref>
          <Dropdown.Item>S&amp;P 500</Dropdown.Item>
        </Link>
        <Dropdown.Divider/>
        <Dropdown.Header>
          Signed in as {user.givenName} {user.familyName}
        </Dropdown.Header>
        {listList}
        <Link href="/sign-out" passHref>
          <Dropdown.Item>Sign Out</Dropdown.Item>
        </Link>
      </>
    ) : (
      <>
        <Link href="/sign-in" passHref>
          <Dropdown.Item>Sign In</Dropdown.Item>
        </Link>
        <Link href="/sign-up" passHref>
          <Dropdown.Item>Sign Up</Dropdown.Item>
        </Link>
      </>
    )

    return (
      <Dropdown>
        <Dropdown.Toggle
          variant="link"
          id="primary-nav-dropdown-toggle"
          className="btn-icon"
        >
          <MenuSvg fill='currentColor' />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          <Dropdown.Header>
            Value Investor Studio
          </Dropdown.Header>
          {userLinks}
        </Dropdown.Menu>
      </Dropdown>
    )
  }
}

export default withRouter(class PrimaryNav extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      showModal: false
    }

    this.companyFinder = React.createRef()

    this.showModal = this.showModal.bind(this)
    this.handleHide = this.handleHide.bind(this)
    this.handleEntered = this.handleEntered.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount() {
    Mousetrap.bind('/', (e) => {
      e.preventDefault()
      this.showModal()
    })
  }

  componentWillUnmount() {
    Mousetrap.unbind('/')
  }

  showModal() {
    this.setState({
      showModal: true
    })
  }

  handleHide() {
    this.setState({
      showModal: false
    }, () => {
      this.companyFinder.current.blur()
    })
  }

  handleEntered() {
    this.companyFinder.current.focus()
  }

  handleSelect() {
    this.setState({
      showModal: false
    })
  }

  render() {
    let search = !this.props.hideSearch ? (
      <Button variant='link' onClick={this.showModal}>
        <span className="d-md-inline d-none">
          Search U.S. Companies&nbsp;
          <Badge variant='light'>/</Badge>
        </span>
        <span className="d-md-none">
          <MagnifyingGlassSvg fill='currentColor'/>
        </span>
      </Button>
    ) : undefined

    return (
      <nav>
        <Container
          fluid
          className='py-3 d-flex align-items-center justify-content-center'
        >
          <div>
            <PrimaryNavMenu/>
          </div>
          <div className='ml-auto'>
            {search}
          </div>
        </Container>
        <Modal
          className="company-finder-modal"
          show={this.state.showModal}
          onHide={this.handleHide}
          onEntered={this.handleEntered}
          backdropClassName="company-finder-backdrop"
        >
          <Modal.Body>
            <CompanyFinder router={this.props.router} ref={this.companyFinder} onSelect={this.handleSelect}/>
          </Modal.Body>
        </Modal>
      </nav>
    )
  }
})
