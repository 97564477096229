const apiService = require('./apiService')
const screenService = require('./screenService')
const financialStatementService = require('./financialStatementService')

class Company {
  constructor(data) {
    Object.assign(this, data)
  }

  fetchCompetitors() {
    return getCompanyCompetitors(this.ticker).then(competitors => {
      this.competitors = competitors
      return competitors
    })
  }

  getPerformanceData() {
    const ticker = this.ticker
    const companyApi = new intrinio.CompanyApi()
    const fundamentalsApi = new intrinio.FundamentalsApi()

    return financialStatementService.getFinancialStatements(
      this.ticker,
      'income_statement'
    ).then(statements => {
      let {labels, dataPoints} = statements.reverse().filter(
        period => period.standardized_financials.filter(f => f.data_tag.tag == 'totalrevenue')[0]
      ).map(period => {
        return {
          label: period.fundamental.fiscal_year,
          dataPoint: period.standardized_financials.filter(f => f.data_tag.tag == 'totalrevenue')[0].value
        }
      }).reduce((acc, cur) => {
        acc.labels.push(cur.label)
        acc.dataPoints.push({
          x: cur.label,
          y: cur.dataPoint
        })

        return acc
      }, {labels: [], dataPoints: []})

      return Promise.resolve({
        labels,
        data: dataPoints,
        company
      })
    })
  }
}

function getCompanyById(id, fields) {
  let url = `/companies/${id}`

  if (Array.isArray(fields)) url += `?fields=${fields.join(',')}`

  return apiService.get(url).then(res => {
    let body = res.data
    let company = new Company(body.data.company || {
      ...body.data.companies[id],
      ticker: id
    })

    return Promise.resolve(company)
  })
}

function getCompanyCompetitors(id) {
  let url = `/companies/${id}/competitors`

  return apiService.get(url).then(res => {
    let body = res.data
    let competitors = body.data

    return Promise.resolve(competitors)
  })
}

function getCompanySuggestions(q) {
  let url = `/companies?q=${encodeURIComponent(q)}`

  return apiService.get(url).then(res => {
    let body = res.data
    let suggestions = body.data.companies

    return Promise.resolve(suggestions)
  })
}

function getCompaniesByIds(ids, fields) {
  let url = `/companies/${ids.join(',')}`

  if (Array.isArray(fields)) url += `?fields=${fields.join(',')}`

  return apiService.get(url).then(res => {
    let body = res.data
    return Promise.resolve(body.data.companies || {[ids[0]]: body.data.company})
  })
}

function getHistoricalData(id, query) {
  let url = `/companies/${id}/historical_data`

  return apiService.get(url, { params: query }).then(res => {
    return res.data.data
  })
}

function allCompanies(options) {
  var logic = {
    "operator": "AND",
    "clauses": [
      {
        field: 'security_code',
        operator: 'eq',
        value: 'EQS'
      }
    ],
    "groups": [
      {
        operator: "OR",
        clauses: [
          {
            field: 'sic',
            operator: 'gt',
            value: 0
          },
          {
            field: 'marketcap',
            operator: 'gt',
            value: 0
          },
          {
            field: 'totalrevenue',
            operator: 'gt',
            value: 0
          },
          {
            field: 'netincometocommon',
            operator: 'lt',
            value: 0
          }
        ]
      }
    ]
  }

  var opts = {
    logic,
    'orderColumn': "sic", // String | Results returned sorted by this column
    'orderDirection': "asc", // String | Sort order to use with the order_column
    'primaryOnly': true, // Boolean | Return only primary securities
    'pageSize': 50000 // Number | The number of results to return. Maximum for this endpoint is 50000.
  }

  if (options && options.compactResults) opts.compact = 'ticker,name,marketcap,totalrevenue,netincometocommon,sic'

  return screenService.screenCompanies(opts)
}

module.exports = {
  getCompanyById,
  getCompanyCompetitors,
  getCompanySuggestions,
  getCompaniesByIds,
  getHistoricalData,
  allCompanies,
}
