import { companyService } from 'value-studio-sdk'
import ListGroup from 'react-bootstrap/ListGroup'
import Form from 'react-bootstrap/Form'
import Spinner from 'react-bootstrap/Spinner'
import Link from 'next/link'
import { withRouter } from 'next/router'

function url(id) {
  return `/company/${id}`
}

export default class CompanyFinder extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      text: '',
      suggestions: [],
      selectedIx: 0,
    }

    this.reset = this.reset.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleSelection = this.handleSelection.bind(this)
    this.mouseOverHandlerForIx = this.mouseOverHandlerForIx.bind(this)
    this.scroll = this.scroll.bind(this)
    this.focus = this.focus.bind(this)
    this.blur = this.blur.bind(this)

    this.finderInput = React.createRef()
    this.suggestionsEl = React.createRef()
  }

  componentDidMount() {
    this.finderInput.current.focus()
  }

  componentDidUpdate() {
    this.focus()
  }

  focus() {
    this.finderInput.current.focus()
  }

  blur() {
    this.finderInput.current.blur()
  }

  scroll(direction) {
    let len = this.state.suggestions.length
    let ix = this.state.selectedIx
    let nextIx

    switch(direction) {
      case 'down':
        nextIx = ix +1 == len ? 0 : ix +1;
        break
      case 'up':
        nextIx = ix == 0 ? len -1 : ix -1;
        break
      default:
        nextIx = ix
    }

    this.setState({ selectedIx: nextIx })
  }

  handleSelection(e) {
    let input = this.finderInput.current

    let selectedSuggestion = this.state.suggestions[this.state.selectedIx] || this.state.suggestions[0]

    if (typeof selectedSuggestion == 'undefined') {
      return
    }

    let metaKey = e.metaKey

    if (metaKey) {
      return window.open(
        url(selectedSuggestion.ticker),
        '_blank'
      )
    }

    input.value = [
      selectedSuggestion.ticker,
      ' - ',
      selectedSuggestion.name
    ].join('')
    input.disabled = true

    this.props.router.push(
      '/company/[id]',
      url(selectedSuggestion.ticker)
    ).then(() => {
      this.reset()
    })
  }

  reset() {
    let input = this.finderInput.current
    if (input) input.disabled = false

    if (this.props.onSelect) this.props.onSelect()
    window.scrollTo(0, 0)

    this.setState({
      text: '',
      suggestions: [],
      selectedIx: 0,
    })
  }

  handleKeyDown(e) {
    switch(e.key) {
      case 'ArrowDown': this.scroll('down'); break
      case 'ArrowUp': this.scroll('up'); break
      case 'Enter': this.handleSelection(e); break
    }

    this.setState({ text: e.target.value })
  }

  handleChange(e) {
    let q = e.target.value
    let that = this
    this.setState({
      isLoading: true
    })

    companyService.getCompanySuggestions(q).then(suggestions => {
      if (that.finderInput.current.value != q) return

      that.setState({
        suggestions: suggestions || [],
        isLoading: false,
        selectedIx: 0
      })
    }).catch(e => {
      console.error('Could not fetch suggestions:', e)
    })
  }

  mouseOverHandlerForIx(ix){
    return (e) => {
      this.setState({ selectedIx: ix })
    }
  }

  render() {
    let suggestionEl;
    let spinner;
    let suggestionElements = this.state.suggestions.map((company, ix) => {
      return (
        <Link href={`/company/${company.ticker}`} key={ix}>
          <ListGroup.Item
            action
            active={ix == this.state.selectedIx}
            onMouseOver={this.mouseOverHandlerForIx(ix)}
          >
            {`${company.ticker} - ${company.name}`}
          </ListGroup.Item>
        </Link>
      )
    })

    if (suggestionElements.length) suggestionEl = (
      <ListGroup className='mt-3' ref={this.suggestionsEl}>
        {suggestionElements}
      </ListGroup>
    )

    if (this.state.isLoading) spinner = <Spinner
      variant='primary'
      animation='grow'
      size='sm'
      role='status'
      style={{ position: 'absolute', right: '10px', top: '11px'}}
    />

    return (
      <div>
        <div style={{position: 'relative'}}>
          <Form.Control
            autoFocus={true}
            ref={this.finderInput}
            type="text"
            className="form-control"
            onKeyDown={this.handleKeyDown}
            onChange={this.handleChange}
            placeholder="Type a company name or ticker symbol (eg. Apple, MS, TSLA)"
            autoComplete="off"
          />
          {spinner}
        </div>
        {suggestionEl}
      </div>
    );
  }
}
