const axios = require('axios')
const axiosRetry = require('axios-retry')
const config = require('config')
const cookies = require('js-cookie')


let cancelSource = axios.CancelToken.source()

let instance = axios.create({
  baseURL: `${config.apiUrl}/v1`,
  cancelToken: cancelSource.token,
})

instance.cancelSource = cancelSource

instance.interceptors.request.use((config) => {
  let idToken = cookies.get('auth_id_token')
  if (idToken) config.headers.common.Authorization = `Bearer ${idToken}`
  return config
})

module.exports = instance
