const StandardizedFinancialStatement = require('./standardized_financial_statement')

class IncomeStatement extends StandardizedFinancialStatement {
  constructor(financials) {
    super(financials)
    this.defineProportionFamilyBoundariesByTagName(
      'totalrevenue',
      'netincometocommon'
    )
  }
}

module.exports = IncomeStatement
