const Financial = require('./financial')

class StandardizedFinancial extends Financial {
  constructor(financial, statement) {
    super(financial, statement, 'data_tag')
  }

  get descendents() {
    let financials = []

    function getDesc(f) {
      let parent = f.data_tag.parent
      if (!parent) return;
      financials.push(parent)
      getDesc(parent)
    }

    getDesc(this)

    return financials
  }

  get denominator() {
    if (this.statement._proportionFamilyBoundaryPairs.length == 0) return undefined;

    let descendentTags = this.descendents.map(f => f.data_tag.tag)
    let denominator;

    for (let [upper, lower] of this.statement._proportionFamilyBoundaryPairs) {
      if (!descendentTags.includes(lower) && this.tag != lower) continue;
      denominator = this.statement.tags[upper]
      break
    }

    return denominator
  }

  display(format) {
    if (format != 'percent') return super.display(format);
    let denominator = this.denominator

    if (!denominator) return undefined

    let percent = (this.value / denominator.value) * 100
    let formattedValue = Math.abs(percent).toLocaleString(undefined, {
      maximumFractionDigits: 1
    })

    if (percent >= 0) return `${formattedValue}%`
    return `(${formattedValue}%)`
  }
}

module.exports = StandardizedFinancial
