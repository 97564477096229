import Container from 'react-bootstrap/Container'
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'

export default class Footer extends React.Component {
  render() {
    return (
      <footer>
        <div className="bg-light py-5">
          <Container>
            <Row>
              <Col className='text-center'>
                <h4>This website is a work in progress</h4>
                <p className='mb-0'>
                  The information presented may not be complete. Report issues, bugs, and suggestions to&nbsp;
                  <a href="mailto:jeff@jeffops.xyz">jeff@jeffops.xyz</a>
                </p>
              </Col>
            </Row>
            <Row className='pt-4 mt-4 border-top'>
              <Col className='text-center'>
                <p className="mb-0">
                  &copy; Jeff Marshall {(new Date()).getFullYear()}
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </footer>
    )
  }
}

