const apiService = require('./apiService')



function getLists() {
  return apiService.get('/lists').then(res => {
    return res.data.data.lists
  })
}

function getList(id) {
  return apiService.get(`/lists/${id}`).then(res => {
    return res.data.data.list
  })
}

function updateList(id, {name, description}) {
  return apiService.put(`/lists/${id}`, { name, description }).then(res => {
    return res.data.data.list
  })
}

function createList(name, description) {
  return apiService.post('/lists', { name, description }).then(res => {
    return res.data.data.list
  })
}

function deleteList(id) {
  return apiService.delete(`/lists/${id}`).then(res => {
    return res.data.data.listDeleted
  })
}

function createListItem(listId, ticker) {
  return apiService.post('/list-items', { ticker, listId }).then(res => {
    return res.data.data.listItem
  })
}

function getListItems(where) {
  let url = `/list-items${where ? '?where=' + encodeURIComponent(JSON.stringify(where)) : ''}`
  return apiService.get(url).then(res => {
    return res.data.data.listItems
  })
}

function deleteListItem(id) {
  return apiService.delete(`/list-items/${id}`).then(res => {
    return res.data.data.listItemDeleted
  })
}



module.exports = {
  createList,
  getLists,
  getList,
  updateList,
  createListItem,
  getListItems,
  deleteList,
  deleteListItem
}
