class Financial {
  constructor(financial, statement, tagFieldName) {
    Object.assign(this, financial)
    this.statement = statement
    this.tagFieldName = tagFieldName
    this.tag = financial[tagFieldName].tag
    this.name = financial[tagFieldName].name
    this.unit = financial[tagFieldName].unit
  }

  toString() {
    return `[Financial ${this[this.tagFieldName].tag}: ${this.value} ${this[this.tagFieldName].unit}]`
  }

  toJSON() {
    let copy = Object.assign({}, this)
    delete copy.statement
    return copy
  }

  display(format) {
    let tagFieldName = this.tagFieldName
    let value = this.value
    let formattedValue = this.value.toLocaleString()

    if (format == 'accounting'){
      if (this[tagFieldName].unit === null) this[tagFieldName].unit = 'usd'
      let shouldBracket = value < 0
      let shouldAbbrev = (value >= 1000000 || value <= -1000000) && [
        'usd',
        'iso4217_USD',
        'shares'
      ].indexOf(this[tagFieldName].unit.toLowerCase()) != -1

      if (!shouldAbbrev && !shouldBracket) return value.toLocaleString()
      if (shouldAbbrev && !shouldBracket) return parseFloat((value/1000000).toFixed(1)).toLocaleString()
      if (!shouldAbbrev && shouldBracket) return `(${Math.abs(value).toLocaleString()})`
      if (shouldAbbrev && shouldBracket) return `(${Math.abs(parseFloat((value/1000000).toFixed(1))).toLocaleString()})`
    }

    return formattedValue
  }
}


module.exports = Financial
