const apiService = require('./apiService')
const User = require('models/user')


module.exports = {
  register(fields) {
    return apiService.post('/users', fields).then(res => {
      return new User(res.data.data.user)
    })
  },

  getProfile() {
    return apiService.get('/users/me').then(res => {
      return new User(res.data.data.user)
    })
  }
}
