const apiService = require('./apiService')
const {
  ReportedFinancialStatement,
  IncomeStatement,
  CashFlowStatement,
  BalanceSheetStatement
} = require('../models')

function getFinancialStatements(id, statement, opts) {
  let statement_code = {
    'income_statement': 'income-statements',
    'balance_sheet_statement': 'balance-sheet-statements',
    'cash_flow_statement': 'cash-flow-statements'
  }[statement]

  let options = opts || {}

  if (typeof options != 'object') {
    throw new Error('Options parameter must be an object')
  }

  if (!statement_code) throw new Error(`Invalid statement: ${statement}`)

  let url = `/companies/${id}/financials/${statement_code}`

  return apiService.get(url, {
    params: {
      period: 'fy',
      standardized: true,
      ...options
    }
  }).then(res => {
    let body = res.data

    let data = body.data.map(stmt_data => {
      if (stmt_data.hasOwnProperty('reported_financials')) {
        return new ReportedFinancialStatement(stmt_data)
      }

      return new {
        'income_statement': IncomeStatement,
        'cash_flow_statement': CashFlowStatement,
        'balance_sheet_statement': BalanceSheetStatement
      }[statement](stmt_data)
    })

    return Promise.resolve(data)
  })
}


module.exports = {
  getFinancialStatements
}
