module.exports = {
  financialStatementService: require('./financialStatementService'),
  companyService: require('./companyService'),
  newsService: require('./newsService'),
  authService: require('./authService'),
  userService: require('./userService'),
  screenService: require('./screenService'),
  listService: require('./listService'),
  apiService: require('./apiService')
}
