const FinancialStatement = require('./financial_statement')

class ReportedFinancialStatement extends FinancialStatement {
  constructor(financials) {
    // sometimes reported financials come back with "dimensions". eg.:
    // ...
    // {
    //    "axis": "srt_ProductOrServiceAxis",
    //    "member": "us-gaap_ProductMember"
    // }
    //
    // Since I don't understand them, and since they add lines to reported
    // financials that have duplicate tags, I'm removing them here.

    let uniqueTags = new Set()

    financials.reported_financials = financials.reported_financials.filter( r => {
      if (uniqueTags.has(r.xbrl_tag.tag)) return false
      uniqueTags.add(r.xbrl_tag.tag)
      return true
    })

    super(financials, 'reported')
  }
}

module.exports = ReportedFinancialStatement
