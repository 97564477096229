const StandardizedFinancialStatement = require('./standardized_financial_statement')
const StandardizedFinancial = require('./standardized_financial')

class CashFlowStatement extends StandardizedFinancialStatement {
  constructor(financials) {
    super(financials)

    let cashFromOpsLine = this.tags['netcashfromoperatingactivities']
    let capexLine = this.tags['purchaseofplantpropertyandequipment']

    let generatedLines = []

    if (capexLine && cashFromOpsLine) {
      generatedLines = [
        cashFromOpsLine,
        capexLine
      ]

      let fcfFinancial = new StandardizedFinancial({
        data_tag: {
          name: 'Free Cash Flow',
          tag: 'freecashflow',
          parent: null,
          sequence: 3,
          unit: cashFromOpsLine.data_tag.unit,
          balance: 'credit',
          factor: '+'
        },
        value: cashFromOpsLine.value + capexLine.value // should this include purchases of intangibles by default?
      }, this)

      this._tags.freecashflow = fcfFinancial

      generatedLines.push(fcfFinancial)
    }

    this.generated_financials = generatedLines
  }
}

module.exports = CashFlowStatement
