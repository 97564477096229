const FinancialStatement = require('./financial_statement')

class StandardizedFinancialStatement extends FinancialStatement {
  constructor(financials) {
    super(financials, 'standardized')
    this._proportionFamilyBoundaryPairs = []

    Object.keys(this._tags).forEach(k => {
      let financial = this._tags[k]

      if (financial.data_tag.parent) {
        financial.data_tag.parent = this._tags[financial.data_tag.parent]
      }
    })
  }

  toJSON() {
    return {
      fundamental: this.fundamental,
      type: this.type,
      tagFieldName: this.tagFieldName,
      standardized_financials: this.standardized_financials.map(
        std_stmt => {
          if (std_stmt.data_tag.parent == null) return std_stmt;
          std_stmt.data_tag.parent = std_stmt.data_tag.parent.tag
          return std_stmt
        }
      )
    }
  }

  defineProportionFamilyBoundariesByTagName(upperTagName, lowerTagName) {
    this._proportionFamilyBoundaryPairs.push([
      upperTagName,
      lowerTagName
    ])
  }
}

module.exports = StandardizedFinancialStatement
