const apiService = require('./apiService')
const storage = require('storage')
const cookies = require('js-cookie')

function jwtDecode(t) {
  let token = {};
  token.raw = t;
  token.header = JSON.parse(window.atob(t.split('.')[0]));
  token.payload = JSON.parse(window.atob(t.split('.')[1]));
  return (token)
}

const authCookieOptions = {
  expires: 360,
  samesite: 'strict'
}


module.exports = {
  signInWithEmail(email, passphrase) {
    return apiService.post('/auth/sign-in', {
      email,
      passphrase
    }).then(res => {
      let idToken = res.data.id_token
      cookies.set('auth_id_token', idToken, authCookieOptions)
      return res.data
    })
  },

  authorizeAppleSignInWithCode(code) {
    return apiService.get('/auth/apple', {
      params: {
        code
      }
    }).then(res => {
      let body = res.data

      return Promise.resolve(body)
    })
  },

  signInWithGoogle(idToken) {
    return apiService.post('/auth/google-sign-in', {
      idToken
    }).then(res => {
      let idToken = res.data.id_token
      cookies.set('auth_id_token', idToken, authCookieOptions)
      return res.data
    })
  },

  signInWithApple(idToken, userInfo) {
    return apiService.post('/auth/apple-sign-in', {
      idToken,
      userInfo,
    }).then(res => {
      let idToken = res.data.id_token
      cookies.set('auth_id_token', idToken, authCookieOptions)
      return res.data
    })
  },

  signOut() {
    cookies.remove('auth_id_token')
  }
}
