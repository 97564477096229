const StandardizedFinancial = require('./standardized_financial')
const ReportedFinancial = require('./reported_financial')

class FinancialStatement {
  constructor(financials, type) {
    Object.assign(this, financials)
    this.type = type
    this.tagFieldName = {
      'standardized': 'data_tag',
      'reported': 'xbrl_tag'
    }[type]

    let appropriateFinancialClass = {
      'standardized': StandardizedFinancial,
      'reported': ReportedFinancial
    }[type]

    this._financials = financials[`${type}_financials`].map(financial => {
      return new appropriateFinancialClass(financial, this)
    })

    if (this.generated_financials){
      this.generated_financials = this.generated_financials.map(
        f => new appropriateFinancialClass(f)
      )
    }

    this._tags = this._financials.map(financial => {
      return [financial[this.tagFieldName].tag, financial]
    }).reduce((acc, cur) => {
      acc[cur[0]] = cur[1]
      return acc
    }, {})
  }

  get financials() {
    return Array.from(this._financials)
  }

  get tags() {
    return Object.assign({}, this._tags)
  }
}

module.exports = FinancialStatement
