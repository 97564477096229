const apiService = require('./apiService')

function getNewsForQuery(q) {
  return apiService.get('/news', {
    params: {
      q: q
    }
  }).then(res => {
    let body = res.data

    return Promise.resolve(body.data.articles)
  })
}

module.exports = {
  getNewsForQuery
}
